import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'src/components/Seo';
import Hero from 'src/components/Hero';
import {
  ContentContainer,
  ContentHeadline,
  ContentBody,
} from 'src/components/HomepageStyles';
import LargeLinkList from 'src/components/LargeLinkList';
import ScrollEntrance from 'src/components/ScrollEntrance';
import NextSectionHome from 'src/components/NextSectionHome';
import VideoPlayerHome from 'src/components/VideoPlayerHome';

const Index = ({ data }) => {
  const { page } = data;
  const { meta, homePage } = page;
  const { content } = homePage;
  const hasHero = !!page.homePage.heroType;

  const renderContentBlock = (headline, body, links) => {
    return (
      <ScrollEntrance>
        <ContentContainer className={`content-container`}>
          {headline && <ContentHeadline>{headline}</ContentHeadline>}
          {body && <ContentBody dangerouslySetInnerHTML={{ __html: body }} />}
          {links && <LargeLinkList list={links} />}
        </ContentContainer>
      </ScrollEntrance>
    );
  };

  return (
    <>
      <Seo meta={meta} />
      {hasHero && (
        <Hero
          headline={homePage.headline}
          text={homePage.body}
          type={homePage.heroType}
          video={homePage.heroVideo}
          image={{
            small: homePage.heroImageMobile && homePage.heroImageMobile,
            large: homePage.heroImage
          }}
        />
      )}
      <section>
        {content.video && (
          <VideoPlayerHome
            videoId={content.video}
            coverVideo={content.coverVideo}
          />
        )}
        {content.text && renderContentBlock(null, content.text)}
        {content.linkHeadline &&
          renderContentBlock(content.linkHeadline, null, content.links)}
      </section>
      {homePage.nextSectionLink && (
        <NextSectionHome
          title={homePage.nextSectionCta}
          subtitle={homePage.nextSectionTitle}
          link={homePage.nextSectionLink}
        />
      )}
    </>
  );
};

export const query = graphql`{
  page: wpPage(uri: {eq: "/"}) {
    title
    id
    slug
    path: uri
    homePage {
      heroType
      heroImage {
        id
        alt: altText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
      heroImageMobile {
        id
        alt: altText
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
          }
        }
      }
      heroVideo {
        file {
          localFile {
            publicURL
          }
        }
        placeholderImage {
          localFile {
            publicURL
          }
        }
      }
      headline
      body
      content: contentSection {
        video
        coverVideo: covervideo {
          localFile {
            publicURL
          }
        }
        text
        linkHeadline
        links {
          image {
            alt: altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 100, height: 100, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          linkText
          url
        }
      }
      nextSectionLink
      nextSectionTitle
      nextSectionCta
    }
    meta: seo {
      title
    }
  }
}
`;

export default Index;
